import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { first, map, Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  private readonly signInPath = '/sign-in';

  constructor(private auth: AuthService, private router: Router) {}

  canLoad(): Observable<boolean> {
    return this.auth.isAuthenticated.pipe(first());
  }

  canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<true | UrlTree> {
    return this.redirectIfUnauthenticated(state);
  }

  canActivateChild(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<true | UrlTree> {
    return this.redirectIfUnauthenticated(state);
  }

  private redirectIfUnauthenticated(
    state: RouterStateSnapshot
  ): Observable<true | UrlTree> {
    const redirectUrl = this.router.parseUrl(
      `${this.signInPath}?redirectTo=${state.url}`
    );
    
    return this.auth.isAuthenticated.pipe(
      map((isAuthenticated) => isAuthenticated || redirectUrl)
    );
  }
}
