import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class MergePatchJsonInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.shouldSendHeader(request)) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/merge-patch+json',
        },
      });
    }
    return next.handle(request);
  }

  private shouldSendHeader(request: HttpRequest<unknown>): boolean {
    return (
      request.method === 'PATCH' && request.url.startsWith(environment.api)
    );
  }
}
