import { Component } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'glx-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent {
  readonly user = this.auth.user;

  constructor(private auth: AuthService) {}

  logout(): void {
    this.auth.logout();
  }
}
