import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, interval, map, shareReplay } from 'rxjs';
import { RuleService } from '../rule.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';

interface timeComponent {
  secondsToExecution: number;
  minutesToExecution: number;
  hoursToExecution: number;
  daysToExecution: number;
}

@Component({
  selector: 'glx-rule-countdown',
  templateUrl: './rule-countdown.component.html',
  styleUrls: ['./rule-countdown.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    OverlayModule
  ]

})
export class RuleCountdownComponent implements OnDestroy, OnInit  {
  private destroyed$ = new Subject<void>();
  isInfoOpen = false;
  isOpen = false;
  nextRuleExecution!: Date;
  datesArray!: Date[];
  timeLeft$!: Observable<timeComponent>;
  hour!: number[];
  error!: string | null;
  constructor(
  private rules: RuleService,

  ) {
    this.error = null;
    this.getRuleExecutionDate();
  }

  ngOnInit(): void {
    this.timeLeft$ = interval(1000).pipe(
      map(() => this.calcDateDiff(this.nextRuleExecution)),
      shareReplay(1)
    );
  }

getRuleExecutionDate(): void {
  this.rules.getRuleExecutionDate().subscribe((res) => {
    if(!res.enabled || !res.scheduleEnabled) {
      this.error = "Rule execution disabled.";
    } else {
      this.datesArray = res.futureScheduledExecutions.map(((x: string) => new Date(x)))
      this.datesArray = this.datesArray.filter((x: Date) => x.getDay() === new Date().getDay())
      const nextExecution = this.datesArray.find((x: Date) => x.getTime() > Date.now())
      if (nextExecution) {
        this.nextRuleExecution = nextExecution;
      }
    }
  });
}


  calcDateDiff(executionDate: Date): timeComponent {
    const executionT = executionDate.valueOf();
  
    const milliSecondsInASecond = 1000;
    const hoursInADay = 24;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;
  
    const timeDifference = executionT - Date.now();
  
    const daysToExecution = Math.floor(
      timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay)
    );

    const hoursToExecution = Math.floor(
      (timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
        hoursInADay
    );
  
    const minutesToExecution = Math.floor(
      (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
        secondsInAMinute
    );
  
    const secondsToExecution =
      Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;
  
    return { secondsToExecution, minutesToExecution, hoursToExecution, daysToExecution };
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  calculateClasses() {
    let classStatement: string = ''

    if(document.body.classList.contains('dark')){
      classStatement = 'dark-info'
    } else {
      classStatement = ''
    }

    return classStatement
  }
}
