<section
  class="container grid-container"
>
  <header class="grid-full-width">
    <mat-icon svgIcon="logo"></mat-icon>
    <h1 class="mat-headline-3">Galaxy</h1>
  </header>
  <h2 class="grid-full-width">Galaxy</h2>
  <a
    mat-stroked-button
    routerLink="/audios"
    class="mat-elevation-z3"
    need-permission="GALAXY_READER"
  >
  <div>
    
  </div>
  <div class="dashboard-button-content">
    <mat-icon>headset</mat-icon>
    <span>Audios</span>
  </div>
    </a
  >
  <a
    mat-stroked-button
    routerLink="/videos"
    class="mat-elevation-z3"
    need-permission="GALAXY_READER"
  >
  <div class="dashboard-button-content">
    <mat-icon>movie</mat-icon>
    <span>Videos</span>
  </div>
  </a
  >
  <a
    mat-stroked-button
    routerLink="/apps"
    class="mat-elevation-z3"
    need-permission="GALAXY_READER"
  >
  <div class="dashboard-button-content">
    <mat-icon>apps</mat-icon>
    <span>Apps</span>
  </div>
   </a
  >
  <a
    mat-stroked-button
    routerLink="/images"
    class="mat-elevation-z3"
    need-permission="GALAXY_READER"
  >
  <div class="dashboard-button-content">
    <mat-icon>photo_library</mat-icon>
    <span>Images</span>
  </div>
    </a
  >
  <a
    mat-stroked-button
    routerLink="/contents"
    class="mat-elevation-z3"
    need-permission="GALAXY_CONTENTS"
  >
  <div class="dashboard-button-content">
    <mat-icon>folder</mat-icon>
    <span>Contents</span>
  </div>
   </a>
   <a
   mat-stroked-button
   routerLink="/carts"
   class="mat-elevation-z3"
   need-permission="GALAXY_CONTENTS"
 >
 <div class="dashboard-button-content">
   <mat-icon>shopping_cart</mat-icon>
   <span>Carts</span>
 </div>
  </a>

  <h2  class="grid-full-width">Collections</h2>
  <a
    mat-stroked-button
    routerLink="/series"
    class="mat-elevation-z3"
    need-permission="GALAXY_READER"
  >
  <div class="dashboard-button-content">
    <mat-icon>live_tv</mat-icon>
    <span>Series</span>
  </div>
    </a
  >
  <a
  mat-stroked-button
  routerLink="/music"
  class="mat-elevation-z3"
  need-permission="GALAXY_READER"
>
<div class="dashboard-button-content">
  <mat-icon class="material-symbols-outlined" fontIcon="library_music"></mat-icon>
  <span>Music</span>
</div>
  </a
>
  <h2 class="grid-full-width">Fusio</h2>
  <a
    mat-stroked-button
    routerLink="/campaigns"
    class="mat-elevation-z3"
    need-permission="FUSIO_READER"
  >
  <div class="dashboard-button-content">
    <mat-icon>campaign</mat-icon>
    <span>Campaigns</span>
  </div>

   </a
   
  >
  <a
  mat-stroked-button
  routerLink="/search-engine-configs"
  class="mat-elevation-z3"
  need-permission="TELESCOPE_ACCESS"
>
<div class="dashboard-button-content">
  <mat-icon>settings</mat-icon>
  <span>Search Engine Configs</span>
</div>

 </a
 
>
  <a
    mat-stroked-button
    routerLink="/rules"
    class="mat-elevation-z3"
    need-permission="FUSIO_RULE"
  >
  <div class="dashboard-button-content">
    <mat-icon>sync</mat-icon>
    <span>Rules</span>
  </div>
    </a
  >

  <h2 class="grid-full-width">Admin</h2>
  <a
    mat-stroked-button
    routerLink="/providers"
    class="mat-elevation-z3"
    need-permission="GALAXY_ADMIN_PROVIDER"
  >
  <div class="dashboard-button-content">
    <mat-icon>hub</mat-icon>
    <span>Providers</span>
  </div>
   </a
  >
  <a
    mat-stroked-button
    routerLink="/themes"
    class="mat-elevation-z3"
    need-permission="GALAXY_ADMIN_THEME"
  >
  <div class="dashboard-button-content">
    <mat-icon>category</mat-icon>
    <span>Themes</span>
  </div>
    </a
  >
  <a
    mat-stroked-button
    routerLink="/import-audio-products"
    class="mat-elevation-z3"
    need-permission="GALAXY_ADMIN_IMPORT_AUDIO"
  >
  <div class="dashboard-button-content">
    <mat-icon>save_alt</mat-icon>
    <span>Import audio products</span>
  </div>
    </a
  >
  <a
    mat-stroked-button
    routerLink="/artists"
    class="mat-elevation-z3"
    need-permission="GALAXY_READER"
  >
  <div class="dashboard-button-content">
    <mat-icon>album</mat-icon>
    <span>Artists</span>
  </div>
   </a
  >

  <footer class="grid-full-width">
    <div slass='footer-wrapper'>
      <glx-dark-mode-toggle></glx-dark-mode-toggle>
      <glx-user-menu></glx-user-menu>
    </div>
  </footer>
</section>
