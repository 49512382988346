
  <button mat-button [matMenuTriggerFor]="menu" aria-label="User actions menu">
    <mat-icon>account_circle</mat-icon>
    {{(user | async)?.login}}
  </button>


<mat-menu #menu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span>Logout</span>
    </button>
  </ng-template>
</mat-menu>
