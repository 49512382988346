import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { Observable } from 'rxjs';
import { routeSwap } from '../core/animations';

@Component({
  selector: 'glx-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [routeSwap],
})
export class HomeComponent {
  mediumScreenState: Observable<BreakpointState>;

  constructor(
    private contexts: ChildrenOutletContexts,
    breakpointObserver: BreakpointObserver
  ) {
    this.mediumScreenState = breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
    ]);
  }

  getRouteAnimationData(): string | undefined {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
      'animation'
    ];
  }

  getRouteTilte(): string | undefined {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
      'title'
    ];
  }
}
