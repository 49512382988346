import { Component } from '@angular/core';

@Component({
  selector: 'glx-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent {
  constructor() {}
}
