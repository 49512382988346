<mat-nav-list>
  <h3 matSubheader class="mat-title-large">Galaxy</h3>
  <a
    mat-list-item
    routerLink="/audios"
    routerLinkActive="active"
    need-permission="GALAXY_READER"
  >
    <h3 matListItemTitle>Audios</h3>
    <mat-icon matListItemIcon>headset</mat-icon>
  </a>
  <a
    mat-list-item
    routerLink="/videos"
    routerLinkActive="active"
    need-permission="GALAXY_READER"
  >
    <h3 matListItemTitle>Videos</h3>
    <mat-icon matListItemIcon>movie</mat-icon>
  </a>
  <a
    mat-list-item
    routerLink="/apps"
    routerLinkActive="active"
    need-permission="GALAXY_READER"
  >
    <h3 matListItemTitle>Apps</h3>
    <mat-icon matListItemIcon>apps</mat-icon>
  </a>
  <a
    mat-list-item
    routerLink="/images"
    routerLinkActive="active"
    need-permission="GALAXY_READER"
  >
    <h3 matListItemTitle>Images</h3>
    <mat-icon matListItemIcon>photo_library</mat-icon>
  </a>
  <a
    mat-list-item
    routerLink="/contents"
    routerLinkActive="active"
    need-permission="GALAXY_CONTENTS"
  >
    <h3 matListItemTitle>Contents</h3>
    <mat-icon matListItemIcon>folder</mat-icon>
  </a>
  <a
  class="last-sibling"
  mat-list-item
  routerLink="/carts"
  routerLinkActive="active"
  need-permission="GALAXY_ADMIN_SERIE"
>
  <h3 matListItemTitle>Carts</h3>
  <mat-icon matListItemIcon>shopping_cart</mat-icon>
</a>
  <mat-divider></mat-divider>
  <h3 mat-subheader>Collections</h3>
  <a
    mat-list-item
    routerLink="/series"
    routerLinkActive="active"
    need-permission="GALAXY_READER"
  >
  <h3 matListItemTitle>Series</h3>
    <mat-icon matListItemIcon>live_tv</mat-icon>
  </a>
  <a
  mat-list-item
  class="last-sibling"
  routerLink="/music"
  routerLinkActive="active"
  need-permission="GALAXY_READER"
>
<h3 matListItemTitle>Music</h3>
  <mat-icon class="material-symbols-outlined" matListItemIcon fontIcon="library_music"></mat-icon>
</a>
  <mat-divider></mat-divider>
  <h3 matSubheader>Fusio</h3>
  <a
    mat-list-item
    routerLink="/campaigns"
    routerLinkActive="active"
    need-permission="FUSIO_READER"
  >
  <h3 matListItemTitle>Campaigns</h3>
  <mat-icon matListItemIcon>campaign</mat-icon>
  </a>
  <a
  mat-list-item
  routerLink="/search-engine-configs"
  routerLinkActive="active"
  need-permission="TELESCOPE_ACCESS"
>
<h3 matListItemTitle>Search Engine Configs</h3>
<mat-icon matListItemIcon>settings</mat-icon>
</a>
  <a
    mat-list-item
    routerLink="/rules"
    class="last-sibling"
    routerLinkActive="active"
    need-permission="FUSIO_RULE"
  >
    <h3 matListItemTitle>Rules</h3>
    <mat-icon matListItemIcon>sync</mat-icon>
  </a>

  <mat-divider></mat-divider>
  <h3 matSubheader>Admin</h3>

  <a
    mat-list-item
    routerLink="/providers"
    routerLinkActive="active"
    need-permission="GALAXY_ADMIN_PROVIDER"
  >
    <h3 matListItemTitle>Providers</h3>
    <mat-icon matListItemIcon>hub</mat-icon>
  </a>
  <a
    mat-list-item
    routerLink="/themes"
    routerLinkActive="active"
    need-permission="GALAXY_ADMIN_THEME"
  >
  <h3 matListItemTitle>Themes</h3>
    <mat-icon matListItemIcon>category</mat-icon>
  </a>
  <a
    mat-list-item
    routerLink="/import-audio-products"
    routerLinkActive="active"
    need-permission="GALAXY_ADMIN_IMPORT_AUDIO"
  >
    <h3 matListItemTitle>Import audio products</h3>
    <mat-icon matListItemIcon>save_alt</mat-icon>
  </a>
  <a
    mat-list-item
    routerLink="/artists"
    class="last-sibling"
    routerLinkActive="active"
    need-permission="GALAXY_READER"
  >
    <h3 matListItemTitle>Artists</h3>
    <mat-icon matListItemIcon>album</mat-icon>
  </a>

  <mat-divider></mat-divider>
  <h3 matSubheader>Docs</h3>

  <a
    mat-list-item
    href="https://coa.dvtech.io/pages/viewpage.action?pageId=1000082374735"
    target="_blank"
  >
  <h3 matListItemTitle>User documentation</h3>
    <mat-icon matListItemIcon>insert_drive_file</mat-icon>
  </a>
  <a
    mat-list-item
    routerLink="/documentation"
    routerLinkActive="active"
    need-permission="GALAXY_READER"
  >
    <h3 matListItemTitle>Classification systems</h3>
    <mat-icon matListItemIcon>lan</mat-icon>
  </a>
</mat-nav-list>
