import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { MergePatchJsonInterceptor } from './core/interceptors/merge-patch-json.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { HomeModule } from './home/home.module';
import { MatListModule } from '@angular/material/list';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { DefaultValueAccessor } from '@angular/forms';
import { NetworkInterceptor } from './interceptors/network.interceptor';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [AppComponent, MaintenanceComponent],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HomeModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatSnackBarModule],
  providers: [
    provideMomentDateAdapter(MY_DATE_FORMATS, {useUtc: true}),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MergePatchJsonInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    provideHttpClient(withInterceptorsFromDi()),
    
  ]
})


export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    matIconRegistry
      .addSvgIcon(
        'logo',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/logo.svg')
      )
      .addSvgIcon(
        'microphone',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/microphone.svg')
      )
      .addSvgIcon(
        'artist',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/artist.svg')
      );
    DefaultValueAccessor.prototype.registerOnChange = function (fn: (_: string | null) => void): void {
      this.onChange = (value: string | null) => {
        fn(value === '' ? null : value);
      };
    };
  }

}
